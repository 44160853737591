import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../../airtables';
import { setGroupTeachers } from '../../../redux/actions';
import { useEffect, useState } from 'react';
import { distinctArray, hasCommonItems } from '../../../utils/array';
import { sendToTeacher } from '../../../libs/apis';
import { toast } from 'react-toastify';

const useHook = (data, type) => {
  const {
    userInfo,
    userType,
    groupTeachers: {
      loaded: teachersLoaded,
      teachers: groupTeachers
    },
    regionsMap
  } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectableTeachers, setSelectableTeachers] = useState([]);

  const [matchedTeachers, setMatchedTeachers] = useState([]);
  const [otherTeachers, setOtherTeachers] = useState([]);

  const [selectedMatchedTeachers, setSelectedMatchedTeachers] = useState([]);
  const [selectedOtherTeachers, setSelectedOtherTeachers] = useState([]);

  const [message, setMessage] = useState("");
  const [ccEmail, setCcEmail] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!data || !type) return;

    let gs = [], ss = [];
    if (type === 'Session') {
      gs = data["Grade(s)"] || [];
      ss = data["Subject"] || []
    } else if (type === 'Event') {
      gs = data["Grade(s)"] || [];
      ss = data["Subject/Curriculum"] || [];
    } else if (type === 'Collection') {
      gs = data["Grades"] || [];
      ss = data["Subjects Covered"] || [];
    } else if (type === "Provider") {
      gs = data["Unique Session Grade(s)"] || [];
      ss = data["Subject/Curriculum"] || [];
    }

    setGrades(distinctArray(gs));
    setSubjects(distinctArray(ss));
  }, [data, type]);

  useEffect(() => {
    if (!teachersLoaded) {
      if (userType === 'Team') {
        airtable.teachers.getTeachersForTeam(userInfo["Email"]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      } else if (userType === 'Teacher' && userInfo["Cluster Leadership"]) {
        airtable.teachers.getClusterTeachers(userInfo["Cluster Lead Text"][0]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedMatchedTeachers([]);
    setSelectedOtherTeachers([]);
    if (!selectableTeachers || !selectableTeachers.length) {
      setMatchedTeachers([]);
      setOtherTeachers([]);
      return;
    }

    const mts = [], ots = [];
    selectableTeachers.forEach(teacher => {
      if (isMatchedTeacher(teacher)) {
        mts.push(teacher);
      } else {
        ots.push(teacher);
      }
    });
    setMatchedTeachers([...mts]);
    setOtherTeachers([...ots]);
    setLoading(false);
  }, [selectableTeachers, subjects, grades]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rIds = groupTeachers.map(gt => gt["School PT"] ? gt["School PT"][0] : "").filter(Boolean)
    const regionIds = distinctArray(rIds);
    const rs = regionIds.map(rId => regionsMap[rId]);
    rs.sort((a, b) => a["Long Name"].localeCompare(b["Long Name"]));
    setRegions(rs);
  }, [groupTeachers, regionsMap])

  useEffect(() => {
    if (!selectedRegions || selectedRegions.length === 0) {
      setSelectableTeachers([...groupTeachers]);
    } else {
      const regionNames = selectedRegions.map(region => region["Name"]);
      const ts = groupTeachers.filter(teacher => regionNames.indexOf(teacher["School P/T Text"]) >= 0);
      setSelectableTeachers(ts);
    }
  }, [selectedRegions, groupTeachers])

  const isMatchedTeacher = (teacher) => {
    const hasMatchingSubject = hasCommonItems(teacher["Subject(s) Taught"], subjects);
    const hasMatchingGrade = hasCommonItems(teacher["Grade(s) Taught"], grades);
    return hasMatchingSubject && hasMatchingGrade;
  }

  const addRecommendationToProviderSession = async (pId, teachers) => {
    const session = await airtable.providerSessions.select(pId);
    let rfsl = session["Recommendations from School Lead"] || [];
    rfsl = [
      ...rfsl,
      ...teachers.map(t => t.id)
    ]
    rfsl = distinctArray(rfsl);
    await airtable.providerSessions.update(session.id, {
      "Recommendations from School Lead": rfsl
    })
  }

  const getUserName = () => {
    return userType === 'Teacher' ? userInfo["Teacher Name"] : userInfo["Name"];
  }

  const sendMessages = async () => {
    if (processing) return;

    const tIds = [...selectedMatchedTeachers, ...selectedOtherTeachers].map(t => t.value);
    const teachers = groupTeachers.filter(t => tIds.indexOf(t.id) >= 0);

    if (!teachers || !teachers.length) return;

    setProcessing(true)
    if (type === 'Session') {
      await sendToTeacher(
        teachers.map(t => t.Email),
        "Session",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data["Session Title"],
        data["Description"],
        `https://sessions.connectednorth.org/cn/session/${data.id}`,
        message,
        ccEmail,
        "",
        "",
        userType
      )

      await addRecommendationToProviderSession(data.id, teachers)
    } else if (type === 'Event') {
      let attachments = null;

      if (data["Flyer"]) {
        attachments = data["Flyer"].map(f => ({
          url: f.url,
          filename: f.filename,
          type: f.type,
          disposition: 'attachment'
        }));
      }

      await sendToTeacher(
        teachers.map(t => t.Email),
        "Event",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data["Session Title Text"][0],
        data["Session Description"][0],
        `https://sessions.connectednorth.org/cn/events`,
        message,
        ccEmail,
        attachments,
        data["Nice Date"],
        userType
      )

      await addRecommendationToProviderSession(data["Provider Session Record ID"][0], teachers)
    } else if (type === 'Collection') {
      await sendToTeacher(
        teachers.map(t => t.Email),
        "Collection",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data.Tag,
        data.Description,
        `https://sessions.connectednorth.org/cn/collection/${data.id}`,
        message,
        ccEmail,
        "",
        "",
        userType
      )

      await airtable.tags.update(data.id, {
        "For Teacher(s)": teachers.map(t => t.id)
      })
    } else if (type === "Provider") {
      await sendToTeacher(
        teachers.map(t => t.Email),
        "Provider",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data.Name,
        data["Introduction/Bio"],
        `https://beta.connectednorth.org/cn/provider/${data.id}`,
        message,
        ccEmail,
        "",
        "",
        userType
      )
    }

    if (userType === 'Team') {
      const teamInfo = await airtable.teams.select(userInfo.id);
      let stn = teamInfo["Sends to Teachers"] || 0;
      await airtable.teams.update(teamInfo.id, {
        "Sends to Teachers": stn + 1
      })
    }

    if (teachers.length === 1) {
      toast.success(`1 Message was successfully sent to the Teacher you selected!`)
    } else {
      toast.success(`${teachers.length} Messages were successfully sent to the Teachers you selected!`)
    }
    setProcessing(false)
  }

  return {
    loading,
    totalTeachers: groupTeachers,
    matchedTeachers,
    otherTeachers,
    selectedMatchedTeachers,
    setSelectedMatchedTeachers,
    selectedOtherTeachers,
    setSelectedOtherTeachers,
    message,
    setMessage,
    ccEmail,
    setCcEmail,
    sendMessages,
    processing,

    regions,
    selectedRegions,
    changeCurrentRegions: setSelectedRegions
  }
}

export default useHook;
