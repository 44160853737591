import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { CurrentGradeLevels } from '../../utils/constants';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import TwoFactorAuthConfirmModal from '../modals/TwoFactorAuthConfirm';

const indigenousGroups = ['First Nations', 'Inuit', 'Métis']
const reminders = ['24 hours before', '1 hour before']

const Screen = () => {
  const { userInfo, totalSubjects } = useSelector(state => state.appInfo)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [language, setlanguage] = useState(userInfo["Preferred Language"] || 'English');
  const [taughtSubjects, setTaughtSubjects] = useState(userInfo["Subject(s) Taught"] || []);
  const [taughtGrades, setTaughtGrades] = useState(userInfo["Grade(s) Taught"] || []);
  const [taughtIndigenous, setTaughtIndigenous] = useState(userInfo["Indigenous Group(s)"] || []);
  const [taughtReminders, setTaughtReminders] = useState(userInfo["E-mail Reminders"] || []);
  const [sessionRequest, setSessionRequest] = useState(userInfo["2024 Session Menu Requested"]);
  const [pronouns, setPronouns] = useState(userInfo["Pronouns"]);
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userInfo["Personal Phone"] || "");
  const [authMethod, setAuthMethod] = useState(userInfo["2FA"] || "None");
  const [_2FAVerificationInfo, set2FAVerificationInfo] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);

  useEffect(() => {
    window.document.title = "My Settings - Connected North"
    setPhoneDisabled(userInfo["2FA"] === "SMS");
  }, [userInfo])

  const toggleTaughtSubjects = (subject) => {
    const pos = taughtSubjects.indexOf(subject.id);
    if (pos < 0) {
      const newSubs = [...taughtSubjects, subject.id];
      setTaughtSubjects(newSubs);
      setChanged(true);
    } else {
      const newSubs = [...taughtSubjects];
      newSubs.splice(pos, 1);
      setTaughtSubjects(newSubs);
      setChanged(true);
    }
  }

  const toggleTaughtGrades = (grade) => {
    const pos = taughtGrades.indexOf(grade);
    if (pos < 0) {
      const newGrades = [...taughtGrades, grade];
      setTaughtGrades(newGrades);
      setChanged(true);
    } else {
      const newGrades = [...taughtGrades];
      newGrades.splice(pos, 1);
      setTaughtGrades(newGrades);
      setChanged(true);
    }
  }

  const toggleTaughtIndigenous = (ti) => {
    const pos = taughtIndigenous.indexOf(ti);
    if (pos < 0) {
      const newTis = [...taughtIndigenous, ti];
      setTaughtIndigenous(newTis);
      setChanged(true);
    } else {
      const newTis = [...taughtIndigenous];
      newTis.splice(pos, 1);
      setTaughtIndigenous(newTis);
      setChanged(true);
    }
  }

  const toggleTaughtReminders = (ti) => {
    const pos = taughtReminders.indexOf(ti);
    if (pos < 0) {
      const newTis = [...taughtReminders, ti];
      setTaughtReminders(newTis);
      setChanged(true);
    } else {
      const newTis = [...taughtReminders];
      newTis.splice(pos, 1);
      setTaughtReminders(newTis);
      setChanged(true);
    }
  }

  const saveChanges = async () => {
    if (phoneNumber && phoneNumber.replace(/\D/g, '').length < 10) {
      setPhoneError(t("mysettings.phone-number-error-length"));
      return;
    }
    setPhoneError("");

    // Add validation for SMS authentication
    if (authMethod === "SMS" && !phoneNumber.trim()) {
      toast.error(t("mysettings.sms-auth-no-phone"));
      return;
    }

    let phoneVerified = userInfo["Phone Number SMS Verified"] ? true : false;
    if (phoneNumber && phoneNumber !== userInfo["Personal Phone"]) {
      phoneVerified = false;
    }

    if (authMethod === "SMS" && !phoneVerified) {
      set2FAVerificationInfo({
        type: "sms",
        phone: phoneNumber,
        uInfo: {}
      });
    } else if (authMethod === "App" && !userInfo["2FA Secret"]) {
      set2FAVerificationInfo({
        type: "app",
        uInfo: { userEmail: userInfo["Email"] }
      });
    }

    try {
      setSaving(true);
      let params = {
        "Preferred Language": language,
        "Subject(s) Taught": taughtSubjects,
        "Grade(s) Taught": taughtGrades,
        "2024 Session Menu Requested": sessionRequest,
        "Pronouns": pronouns,
        "Indigenous Group(s)": taughtIndigenous,
        "E-mail Reminders": taughtReminders,
        "2FA": authMethod,
        "Personal Phone": phoneNumber || null, // Allow removal of phone number
      }

      if (authMethod !== "App" && userInfo["2FA"] !== authMethod) {
        params["2FA Secret"] = "";
      }
      const newUserInfo = await airtable.teachers.update(userInfo.id, params);
      delete newUserInfo["24 Report Message"];
      dispatch(setAppUserInfo(newUserInfo));
      setChanged(false);
      setSaving(false);
      toast.success(t("my-settings-saving-success"));
    } catch (error) {
      setSaving(false);
      toast.error(error.toString())
    }
  }

  const verifyPost = async (type, secret) => {
    let nUserInfo = null;
    if (type === "sms") {
      nUserInfo = await airtable.teachers.update(userInfo.id, {
        "Personal Phone": phoneNumber,
        "Phone Number SMS Verified": true,
        "2FA": "SMS"
      });
    } else if (type === "app") {
      nUserInfo = await airtable.teachers.update(userInfo.id, {
        "2FA Secret": secret,
        "2FA": "App"
      });
    }

    if (nUserInfo) {
      await setSession(CONNECTEDNORTH_SIGNINFO, nUserInfo);
      dispatch(setAppUserInfo(nUserInfo));
      set2FAVerificationInfo(null);
      toast.success(t("mysettings.login-security-enabled"));
    }
  }

  return (
    <div className="main-container">
      <h1>{t("my-settings")}</h1>

      {
        (userInfo['School Name Text'] && userInfo['School Name Text'][0] !== "École des Trois-Soleils") && !(userInfo['Limit Booking to Collection'] && userInfo['Limit Booking to Collection'].length) ? (
          <div className="ms-part" data-intercom-target="Menu Request">
            <span className="title">{t("mysettings.session-menu-request")}</span>
            <p>
              <Trans i18nKey="mysettings.session-menu-request-msg">
                While you can access the Connected North <a href="https://takingitglobal.uberflip.com/i/1507218-connected-north-2023-24-session-menu/0" target='_blank' rel="noreferrer">Session Menu online here</a>, we're also happy to send you a printed copy in the mail! Would you like to receive one?
              </Trans>
            </p>
            <div className="custom-control custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
                defaultChecked={sessionRequest}
                onChange={(e) => {
                  setSessionRequest(!sessionRequest)
                  setChanged(true);
                }}
              />
              <label className="custom-control-label" htmlFor="customCheck1">{t("mysettings.session-menu-request-check")}</label>
            </div>
          </div>
        ) : null
      }

      <div className="ms-part" data-intercom-target="Language">
        <span className="title">Preferred Language</span>
        <div className="ms-part-container">
          <Input
            type="select"
            className='pronouns form-control'
            value={language}
            onChange={(e) => {
              setlanguage(e.target.value)
              setChanged(true);
            }}
          >
            <option value="English">English</option>
            <option value="French">French</option>
          </Input>
        </div>
      </div>

      <div className="ms-part" data-intercom-target="Pronouns">
        <span className="title">{t("pronouns")}</span>
        <p>{t("mysettings.pronoun-msg")}</p>
        <div className="ms-part-container">
          <Input
            className='pronouns'
            defaultValue={pronouns}
            onChange={(e) => {
              setPronouns(e.target.value)
              setChanged(true);
            }}
            type="text"
          />
        </div>
      </div>

      <div className="ms-part" data-intercom-target="Subjects Taught">
        <span className="title">{t("mysettings.subjects")}</span>
        <p>{t("mysettings.subjects-msg")}</p>
        <div className="ms-part-container">
          {
            totalSubjects.filter(sub => sub.Subject !== "Premium Session" && sub.Subject !== "Holiday Themed" && sub.Subject !== "Therapy (SLP/OT)").map((sub, i) => (
              <div
                className={taughtSubjects.indexOf(sub.id) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                key={i}
                onClick={() => toggleTaughtSubjects(sub)}
              ><span>{sub.Subject}</span></div>
            ))
          }
        </div>
      </div>

      <div className="ms-part" data-intercom-target="Grades Taught">
        <span className="title">{t("mysettings.grades")}</span>
        <div className="ms-part-container">
          {
            CurrentGradeLevels.map((grade, i) => (
              <div
                className={taughtGrades.indexOf(grade) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                key={i}
                onClick={() => toggleTaughtGrades(grade)}
              ><span>{t(`grade-list.${grade}`)}</span></div>
            ))
          }
        </div>
      </div>

      <div className="ms-part" data-intercom-target="Session Reminders">
        <span className="title">Session Reminders</span>
        <p>In addition to receiving a calendar invite, we can also send you an optional e-mail reminder 24 hours and/or 1 hour prior to each Connected North Session. Select the reminder e-mails you would like below:</p>
        <div className="ms-part-container">
          {
            reminders.map((ig, i) => (
              <div
                className={classNames({
                  "multicheck-item": true,
                  "item-checked": taughtReminders && taughtReminders.indexOf(ig) >= 0
                })}
                key={i}
                onClick={() => toggleTaughtReminders(ig)}
              ><span>{ig}</span></div>
            ))
          }
        </div>
      </div>

      <div className="ms-part" data-intercom-target="Indigenous Status">
        <span className="title">Indigenous Status (Optional)</span>
        <p>We welcome you to let us know if you identify as First Nations, Inuit and/or Métis as we may offer professional learning opportunities specific to indigenous educators in the future.</p>
        <div className="ms-part-container">
          {
            indigenousGroups.map((ig, i) => (
              <div
                className={classNames({
                  "multicheck-item": true,
                  "item-checked": taughtIndigenous && taughtIndigenous.indexOf(ig) >= 0
                })}
                key={i}
                onClick={() => toggleTaughtIndigenous(ig)}
              ><span>{t(`indigenousGroups.${ig}`)}</span></div>
            ))
          }
        </div>
      </div>

      <div className="ms-part phone-number" data-intercom-target="Phone Number">
        <span className="title">{t("phone-number")}</span>
        <p>{t("mysettings.phone-number-info")}</p>
        <div className="ms-part-container">
          <Input
            type="text"
            className="form-control phone-number"
            placeholder={t("mysettings.phone-number-placeholder")}
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value)
              setPhoneError("");
              setChanged(true);
            }}
            disabled={phoneDisabled}
          />
        </div>
        {phoneError && <div className="text-danger mt-1">{phoneError}</div>}
        {phoneDisabled && (
          <p className="phone-error1">{t("mysettings.phone-number-disabled")}</p>
        )}
        {(!userInfo["Personal Phone"] && authMethod === "SMS") ? (
          <p className="phone-error1">{t("mysettings.phone-number-add-for-sms")}</p>
        ) : (!userInfo["Phone Number SMS Verified"] && authMethod === "SMS") && (
          <p className="phone-error1">
            {t("mysettings.phone-number-not-verified")}{" "}
            <span
              onClick={() => {
                set2FAVerificationInfo({
                  type: "sms",
                  phone: phoneNumber,
                });
              }}
            >
              {t("mysettings.phone-number-verify-here")}
            </span>.
          </p>
        )}
      </div>

      <div className="ms-part" data-intercom-target="Login Security">
        <span className="title">{t("mysettings.login-security")}</span>
        <p>When logging in, to provide additional security you can verify your login request with a SMS Text Message to your mobile device, or a smartphone-based authentication app, such as Google Authenticator or Microsoft Authenticator. If you wish to enable this, please select your preference below:</p>
        <div className="ms-part-container">
          <Input
            type="select"
            className="_2fa-selector form-control"
            value={authMethod}
            onChange={(e) => {
              setAuthMethod(e.target.value)
              setChanged(true);
            }}
          >
            <option value="None">None</option>
            <option value="SMS">SMS (Text Message)</option>
            <option value="App">Authenticator App</option>
          </Input>
        </div>
        {(!userInfo["2FA Secret"] && authMethod === "App") && (
          <p className="_2fa-error1"><span
            onClick={() => {
              set2FAVerificationInfo({
                type: "app",
                uInfo: { userEmail: userInfo["Email"] }
              });
            }}
          >Click here to set up an authenticator app</span> to receive passcodes.</p>
        )}
      </div>

      <div className="ms-action" data-intercom-target="Save">
        <Button
          type="button"
          color="primary"
          disabled={!changed || saving || (authMethod === "SMS" && !phoneNumber.trim())}
          onClick={() => saveChanges()}
        >{saving && (<Loading size={14} />)}{t("mysettings.save-change")}</Button>
      </div>

      {!!_2FAVerificationInfo && (
        <TwoFactorAuthConfirmModal
          type={_2FAVerificationInfo.type}
          phone={_2FAVerificationInfo.phone}
          uInfo={_2FAVerificationInfo.uInfo}
          onDismiss={() => set2FAVerificationInfo(null)}
          onVerifiedPost={verifyPost}
        />
      )}
    </div>
  )
}

export default Screen;
