import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Loading from "../../../components/Loading";
import Placeholder from "../../../assets/img/login-back.jpg"
import ReactMarkdown from 'react-markdown';

export const SessionBio = ({
  session,
  toggleFavorite,
  favoriteState,
  loading,
  requestSession,
  showBookSessionModal,
  showAssignTagModal,
  showSendToTeacher
}) => {
  const {
    userType,
    userInfo,
  } = useSelector((state) => state.appInfo);

  const { t } = useTranslation();

  const renderActions = () => {
    if (loading || !session) return null;

    if (userType === "Teacher") {
      const renderFavoriteStatus = () => {
        if (favoriteState === "loading") {
          return (
            <div className="btn-favorite">
              <Loading size={20} />
            </div>
          )
        }

        return (
          <Fragment>
            <Col className="text-center text-lg-left">
              <Button
                color="outline-primary"
                className="session-detail-button"
                type="button"
                onClick={() => toggleFavorite()}
                disabled={favoriteState === 'loading'}
              >
                {t("add-to-fav")}{" "}
                <i
                  className={
                    favoriteState ===
                      "unfavorite"
                      ? "fa fa-heart-o heart-icon"
                      : "fa fa-heart fa-lg heart-icon"
                  }
                />
              </Button>
            </Col>
            {!!userInfo["Cluster Leadership"] && (
              <Col className="text-center text-lg-left">
                <Button
                  color="outline-primary"
                  type="button"
                  className="session-detail-button"
                  onClick={() => showSendToTeacher(true)}
                >
                  Send to Teachers{" "}
                  <i className="fa fa-envelope" />
                </Button>
              </Col>
            )}
          </Fragment>
        )
      }

      if (session["Available in Events?"]) {
        return (
          <Row className="justify-content-center flex-column flex-md-row flex-lg-column" style={{ gap: "15px" }}>
            <Col className="text-center text-lg-left">
              <Button
                color="primary"
                type="button"
                className="session-detail-button"
                onClick={() => window.location.href = `/cn/events`}
              >
                Register Free in Events
              </Button>
            </Col>
            {(session["Status"] === "Active (Open for booking)" ||
              session["Status"] === "Consider Another Option (See notes)") && (
                <Col className="text-center text-lg-left">
                  <Button
                    color="secondary"
                    type="button"
                    className="session-detail-button"
                    onClick={() => requestSession()}
                  >
                    Request Private Session
                  </Button>
                </Col>
              )}

            {renderFavoriteStatus()}
          </Row>
        )
      }

      if ((session["Status"] === "Active (Open for booking)" || session["Status"] === "Consider Another Option (See notes)") &&
        (
          (!session["Cluster-specific"]) || 
          (Array.isArray(session["Cluster-specific Cluster IDs"]) && session["Cluster-specific Cluster IDs"].some(clusterId => userInfo['Cluster'].includes(clusterId)))
        ) &&
        (!session["Prerequisite Session"] || (
          Array.isArray(userInfo["Provider Sessions Completed"]) && 
          Array.isArray(session["Prerequisite Session"]) && 
          session["Prerequisite Session"].every(prereq => 
            userInfo["Provider Sessions Completed"].includes(prereq)
          )
        ))) {

        return (
          <Row className="justify-content-center flex-column flex-md-row flex-lg-column" style={{ gap: "15px" }}>
            <Col className="text-center text-lg-left">
              <Button
                color="primary"
                type="button"
                className="session-detail-button"
                onClick={() => requestSession()}
              >
                {t("request-session")}
              </Button>
            </Col>

            {renderFavoriteStatus()}
          </Row>
        )
      }

      if (session["Prerequisite Session"]) {
        return (
          <Row className="justify-content-center flex-column flex-md-row flex-lg-column" style={{ gap: "15px" }}>
            <Col className="text-center text-lg-left">
              <Button
                color="primary"
                type="button"
                className="session-detail-button"
                onClick={() => window.location.href = `/cn/session/${session["Prerequisite Session"][0]}`}
              >
                {t("view-prerequisite")}
              </Button>
            </Col>

            {renderFavoriteStatus()}
          </Row>
        )
      }

      return (
        <Row className="justify-content-center flex-column flex-md-row flex-lg-column" style={{ gap: "15px" }}>
          {renderFavoriteStatus()}
        </Row>
      )
    }

    if (userType === "Team" && userInfo["Status"] !== 'Session Host') {
      return (
        <Row className="justify-content-center flex-column flex-md-row flex-lg-column" style={{ gap: "15px" }}>
          <Col className="text-center text-lg-left">
            <Button
              color="primary"
              type="button"
              className="session-detail-button"
              onClick={() => showBookSessionModal()}
            >
              Book Session
              {String(session["Autobook Provider?"]) === 'true' && <> <i className="fa fa-magic" aria-hidden="true"></i></>}
            </Button>
          </Col>
          <Col className="text-center text-lg-left">
            <Button
              color="outline-primary"
              type="button"
              className="session-detail-button"
              onClick={() => showAssignTagModal(true)}
            >
              Add to Collection{" "}
              <i className="fa fa-folder-o" />
            </Button>
          </Col>
          <Col className="text-center text-lg-left">
            <Button
              color="outline-primary"
              type="button"
              className="session-detail-button"
              onClick={() => showSendToTeacher(true)}
            >
              Send to Teachers{" "}
              <i className="fa fa-envelope" />
            </Button>
          </Col>
        </Row>
      )
    }
  }

  return (
    <Col xs="12" lg="9" className="mb-6 session-bio">
      <Row className="mb-4">
        <Col lg="8" className="mb-3">
          {session['Optimized Image'] && session['Optimized Image'].length ? (
            <div className="session-detail-session-image">
              <img src={session['Optimized Image'][0].url} alt="" />
            </div>
          ) : session["Image/Photo"] &&
            session["Image/Photo"].length ? (
            <div className="session-detail-session-image">
              <img
                src={session["Image/Photo"][0].url}
                alt=""
              />
            </div>
          ) : (
            <div className="session-detail-session-image">
              <img
                src={Placeholder}
                alt=""
              />
            </div>
          )
          }
        </Col>
        <Col lg="4">
          {renderActions()}
        </Col>
      </Row>
      <h1>
        {!session ? "Session Detail" : session["Session Title"]}
      </h1>

      {session["Description"] ? (
        <>
          <h2 className="h5 font-weight-bold">Description</h2>
          <div style={{ whiteSpace: 'pre-line' }} className="mb-3">{session["Description"]}</div>
        </>
      ) : null}

      {session["Materials Info"] && session["Materials Info"].trim().length > 0 ? (
        <div style={{ paddingTop: "10px" }}>
          <h2 className="h5 font-weight-bold">
            {session["Supplies"] ? t("materials-required") : t("materials-to-provide")}
          </h2>
          <ReactMarkdown>{session["Materials Info"]}</ReactMarkdown>
        </div>
      ) : null}
      {/* <div style={{ paddingTop: "10px" }}>
          <h5 className="font-weight-bold">
            {t("related-resource")}:
          </h5>
          <div>
            <span>{session["Materials Info"]}</span>
          </div>
        </div> */}
    </Col>
  )
}
