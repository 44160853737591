import React, { Fragment, useEffect, useState } from "react";
import { getNiceFormatDateTimeString } from "../../utils/time";
import ProviderSessionReject from "../../pages/modals/RejectSession/Provider";
import TeacherSessionReject from "../../pages/modals/RejectSession/Teacher";
import { useDispatch, useSelector } from "react-redux";
import airtable from "../../airtables";
import { setMyFavorites } from "../../redux/actions";
import Loading from "../../components/Loading";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import Placeholder from "../../assets/img/login-back.jpg";
import { toast } from "react-toastify";
import ProviderIcon from "../../assets/img/icons/menu/provider.svg";
import TeacherIcon from "../../assets/img/icons/menu/teacher.svg";
import classnames from "classnames";
import { sortGrades } from "../../utils/session";
import { TokenBlue } from "../../pages/Credit/assets/token-blue";
import { confirmAlert } from "react-confirm-alert";
import { openURL } from "../../utils/url";
import { Fade, Menu, MenuItem } from "@mui/material";
import { setRequestSessionModal } from "../../redux/modalActions";
import { getDayRestrictionConfig } from "../../utils/constants";

const SessionInfo = ({
  session,
  noMenu = false,
  displayInfos = [],
  onRefresh,
  actionMenu = [],
  hideProvider = false,
  assignPresenter,
  reassignSessionLead,
  requestSupport,
  sessionDetailLink,
  openInternalIDModal,
  changeStatus,
  editRequest,
  cancelRequestedOrBookedSession,
  confirmBooking,
  bookConfirm,
  postSessionReject,
  rateSession,
  requestPayment,
  generateInvoice,
  openSessionResult,
  openProviderFeedbackModal,
  openAddCILCReuqestIDModal,
  openAddMyClassModal,
  cancelSession,
  bookSession,
  bookOnCILC,
  addToCollection,
  sendToTeacher,
  studentSurvey,
  duplicateSession,
  assignToFundingPool,
  hostSessionCallback,
  dateType = "normal",
  hideActionContacts = false,
  rubriclink = false,
}) => {
  const {
    userType,
    userInfo,
    appLocale,
    myFavorites,
    presentersForProvider,
    subjectMap
  } = useSelector((state) => state.appInfo);

  const [openMenu, setOpenMenu] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [favoriteState, setFavoriteState] = useState("loading");
  const [grades, setGrades] = useState([]);
  const providerSessionId = session["Session Title"]
    ? typeof session["Session Title"] === "string"
      ? session["id"]
      : session["Session Title"][0]
    : "";
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openInNewTabMenuOpen = Boolean(anchorEl);

  const handleSessionDetailBtnClick = (event) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };

  const handleSessionDetailBtnClose = () => {
    setAnchorEl(null);
  };

  const openInNewTab = (event) => {
    handleSessionDetailBtnClose();

    window.open(`/cn/session/${sessionDetailLink}`, "_blank")
  }

  const canDisplayInfo = (infoName) => {
    if (!displayInfos) return false;

    return displayInfos.indexOf(infoName) >= 0;
  };

  const canDisplayMenu = (menuName) => {
    if (!actionMenu) return false;
    return actionMenu.indexOf(menuName) >= 0;
  };

  useEffect(() => {
    if (userType === "Teacher" && canDisplayInfo("Favorite")) {
      setFavoriteState(
        myFavorites.indexOf(providerSessionId) >= 0
          ? "favorite"
          : "unfavorite",
      );
    }
    if (session["Grade(s)"]) {
      setGrades(sortGrades(session["Grade(s)"]));
    }
  }, [myFavorites, providerSessionId, session["Grade(s)"]]); // eslint-disable-line react-hooks/exhaustive-deps

  const gotoURL = (e, url, newWindow = false) => {
    e.preventDefault();
    if (newWindow) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const toggleFavorite = async () => {
    if (!canDisplayInfo("Favorite")) return;
    if (favoriteState === "loading") return;

    if (favoriteState === "favorite") {
      setFavoriteState("loading");
      const favorites1 = [...myFavorites].filter(
        (v) => v !== providerSessionId,
      );
      await airtable.teachers.update(userInfo["id"], {
        Favourites: favorites1,
      });
      setFavoriteState("unfavorite");
      dispatch(setMyFavorites(favorites1));
    } else if (favoriteState === "unfavorite") {
      setFavoriteState("loading");
      const favorites2 = [...myFavorites, providerSessionId];
      await airtable.teachers.update(userInfo["id"], {
        Favourites: favorites2,
      });
      setFavoriteState("favorite");
      dispatch(setMyFavorites(favorites2));
    }
  };

  function removePrefix(timezone) {
    return timezone.replace(/^(Canada\/|USA\/)/, '');
  }

  const copyLink = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.info("Copied to Clipboard!");
  };

  if (!userInfo || !session) return null;

  // Depending on whether the Session is a Provider Session or Booked, the link will be different
  const airtablePS = `https://airtable.com/appP1kThwW9zVEpHr/tblzrZuVRAirv0pAI/${session.id}?blocks=hide`;
  const airtableS = `https://airtable.com/appP1kThwW9zVEpHr/tblRqP4N6gdA8WZEd/${session.id}?blocks=hide`;
  const airtableLink = session["Session Start Date/Time"] ? airtableS : airtablePS;

  const validSubjectsCurriculum = (Array.isArray(session["Subject/Curriculum"]) ? session["Subject/Curriculum"].filter(subjectId => subjectMap[subjectId]) : []);
  const validSubjects = (Array.isArray(session["Subject"]) ? session["Subject"].filter(subjectId => subjectMap[subjectId]) : []);

  const suf = (field) => {
    if (!field || field === "0" || field === 0 || field < 1) return t("free");
    return `${field} ${field === 1 ? t("credit") : t("credits")}`
  }
  const getCreditStatus = () => {
    if (session["Credits"] === 0 || session["Type of Session"] === "Premium Session" || session["Type of Session"] === "Spotlight Series" || (session["Type of Session"] === "Teacher PD" && session["Custom Session Title"] && session["Custom Session Title"].includes("Group Professional Learning"))) return t("free");
    if (
      session["Indigenous Group(s)"] &&
      Array.isArray(session["Indigenous Group(s)"]) &&
      (
        typeof userInfo["School Indigenous Group(s)"] === 'string' ||
        Array.isArray(userInfo["School Indigenous Group(s)"])
      )
    ) {
      if (session["Indigenous Group(s)"].some(group => userInfo["School Indigenous Group(s)"].includes(group))) {
        return suf(session["Discounted Credits"]);
      } else {
        return suf(session["Credits"])
      }
    } else {
      return suf(session["Credits"])
    }
  }

  const callProvider = (e) => {
    if (
      userType === 'Team' &&
      session["Provider Special Instructions"] &&
      session["Provider Special Instructions"][0]
    ) {
      confirmAlert({
        title: 'Special Booking Instructions',
        message: session["Provider Special Instructions"][0],
        buttons: [{
          label: `Continue to Call ${session["Provider Name"]}`,
          onClick: () => {
            gotoURL(e, `tel:${session["Provider Phone"]}`)
          }
        },
        {
          label: "Cancel",
          onClick: () => { }
        }]
      });
    } else {
      gotoURL(e, `tel:${session["Provider Phone"]}`)
    }
  }

  const emailProvider = (e) => {
    if (
      userType === 'Team' &&
      session["Provider Special Instructions"] &&
      session["Provider Special Instructions"][0]
    ) {
      confirmAlert({
        title: 'Special Booking Instructions',
        message: session["Provider Special Instructions"][0],
        buttons: [{
          label: `Continue to E-mail ${session["Provider Name"]}`,
          onClick: () => gotoURL(e, `mailto:${session["Provider Email"]}`, true)
        },
        {
          label: "Cancel",
          onClick: () => { }
        }]
      });
    } else {
      gotoURL(e, `mailto:${session["Provider Email"]}`, true)
    }
  }

  const renderActionMenu = () => {
    if (!actionMenu || actionMenu.length === 0) return;
    if (noMenu) return null;

    return (
      <Dropdown
        isOpen={openMenu}
        toggle={() => setOpenMenu(!openMenu)}
      >
        <DropdownToggle
          className="dropdownToggle"
          style={{
            padding: "8px 14px",
            borderRadius: "50%",
            textAlign: "center",
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none"
          }}
        >
          <i className="fa fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu style={{ right: "0" }}>
          {canDisplayMenu("join-session") && session["WebEx/Zoom Link"] && (
            <DropdownItem
              href="#"
              onClick={(e) => openURL(session["WebEx/Zoom Link"])}
              disabled={!session["WebEx/Zoom Link"].startsWith("http")}
            >
              {t("join-session")}
            </DropdownItem>
          )}

          {canDisplayMenu("copy-link") && (
            <DropdownItem
              href="#"
              onClick={(e) => copyLink(session["WebEx/Zoom Link"])}
            >
              {t("copy-session-join-link")}
            </DropdownItem>
          )}

          {canDisplayMenu("edit-session") && userInfo["Status"] !== 'Session Host' && (
            <DropdownItem
              href="#"
              onClick={(e) => editRequest(session)}
              disabled={!editRequest}
            >
              {t("edit-session")}
            </DropdownItem>
          )}

          {canDisplayMenu("copy-provider-session-link") && (
            <DropdownItem
              href="#"
              onClick={(e) => copyLink(session["Platform Link"])}
            >
              {t("copy-session-details-link")}
            </DropdownItem>
          )}

          {userType === 'Team' && session["School Dial-in"] && (
            <DropdownItem
            href="#"
            onClick={(e) => copyLink(session["School Dial-in"])}
          >
            Copy School SIP Address
          </DropdownItem>
          )}

          {canDisplayMenu("track-supplies") && session["Resource Tracking URL"] && session["Resource Tracking URL"].length && session["Resource Tracking URL"][0] && session["Resource Tracking URL"][0].startsWith("http") && (
            <DropdownItem
              href="#"
              onClick={(e) => openURL(session["Resource Tracking URL"][0])}
            >
              {t("track-supplies-shipment")}
            </DropdownItem>
          )}

          {canDisplayMenu("call-school-lead") && (
            <DropdownItem
              href="#"
              onClick={(e) => gotoURL(e, `tel:${session["School Lead Phone"]}`)}
              disabled={
                !session["Bridge Address / SIP URI"] ||
                session["Bridge Address / SIP URI"].startsWith("http")
              }
            >
              {i18n.t("call-sbd", {
                somebody: session["School Lead Text"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("email-school-lead") && (
            <DropdownItem
              href="#"
              onClick={(e) => gotoURL(e, `mailto:${session["School Lead Email"]}`, true)}
            >
              {i18n.t("email-sbd", {
                somebody: session["School Lead Text"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("call-session-host") && (
            <DropdownItem
              href="#"
              onClick={(e) => gotoURL(e, `tel:${session["Host Phone"]}`)}
            >
              {i18n.t("call-sbd", {
                somebody: session["Session Host Text"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("email-session-host") && (
            <DropdownItem
              href="#"
              onClick={(e) => gotoURL(e, `mailto:${session["Session Host Email"]}`, true)}
            >
              {i18n.t("email-sbd", {
                somebody: session["Session Host Text"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("email-teacher") && !hideActionContacts && (
            <DropdownItem
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default action
                const teacherEmails = session["Teacher Email"];
                if (Array.isArray(teacherEmails) && teacherEmails.length > 1) {
                  if (window.confirm("When e-mailing groups, please copy multiple e-mail addresses into the bcc field, and put yourself as the to line to protect privacy!")) {
                    gotoURL(e, `mailto:${teacherEmails.join(',')}`, true);
                  }
                } else {
                  gotoURL(e, `mailto:${teacherEmails}`, true);
                }
              }}
            >
              {i18n.t("email-sbd", {
                somebody: Array.isArray(session["Teacher Name"]) && session["Teacher Name"].length > 1
                  ? `${session["Teacher Name"].length} Participating Teachers`
                  : session["Teacher Name"]
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("call-teacher") && !hideActionContacts && session["Teacher Phone"] && (
            Array.isArray(session["Teacher Phone"]) && session["Teacher Phone"].length === 1 ? (
              <DropdownItem
                href="#"
                onClick={(e) => gotoURL(e, `tel:${session["Teacher Phone"][0]}`)}
              >
                {i18n.t("call-sbd", {
                  somebody: Array.isArray(session["Teacher Name"]) ? session["Teacher Name"][0] : session["Teacher Name"],
                })}
              </DropdownItem>
            ) : null
          )}

          {canDisplayMenu("call-provider") && !hideActionContacts && session["Provider Phone"] && (
            <DropdownItem
              href="#"
              onClick={(e) => callProvider(e)}
            >
              {i18n.t("call-sbd", {
                somebody: session["Provider Name"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("email-provider") && !hideActionContacts && (
            <DropdownItem
              href="#"
              onClick={(e) => emailProvider(e)}
            >
              {i18n.t("email-sbd", {
                somebody: session["Provider Name"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("dial-bridge-address") && session["Bridge Address / SIP URI"] && session["Bridge Address / SIP URI"].search("@") >= 0 && (
            <DropdownItem
              href="#"
              onClick={(e) => gotoURL(e, `webexteams://meet?sip=${session["Bridge Address / SIP URI"]}`)}
              disabled={
                !(
                  session["Bridge Address / SIP URI"] &&
                  session["Bridge Address / SIP URI"].search("@") >= 0
                )
              }
            >
              {i18n.t("dial-sbd", {
                somebody: session["Bridge Address / SIP URI"],
              })}
            </DropdownItem>
          )}

          {canDisplayMenu("reassign-session-lead") && userType === 'Team' && userInfo["Status"] !== 'Session Host' && (
            <DropdownItem
              href="#"
              onClick={(e) => reassignSessionLead(e, session)}
              disabled={!reassignSessionLead}
            >
              {t("reassign-session-host")}
            </DropdownItem>
          )}

          {canDisplayMenu("request-support") && requestSupport && (
            <DropdownItem
              href="#"
              onClick={(e) => requestSupport(e, session)}
            >
              {t("request-support")}
            </DropdownItem>
          )}

          {canDisplayMenu("show-welcome-map") && session["Session Welcome Map"] && (
            <DropdownItem
              href="#"
              onClick={(e) => openURL(session["Session Welcome Map"])}
            >
              Open Welcome Map
            </DropdownItem>
          )}

          {canDisplayInfo("open-detail") && !!sessionDetailLink && (
            <DropdownItem
              href="#"
              onClick={(e) => window.open(`/cn/session/${sessionDetailLink}`, "_blank")}
              disabled={!sessionDetailLink}
            >
              {t("open-session-detail")}
            </DropdownItem>
          )}
          {canDisplayMenu("open-airtable") && userType === 'Team' && userInfo["Status"] !== 'Session Host' && session.id && (
            <DropdownItem
              target="_blank"
              href={airtableLink}
            >
              Open in Airtable
            </DropdownItem>
          )}
          {canDisplayMenu("rate-session") && session["# Teachers"] < 2 && (
            <DropdownItem
              href="#"
              onClick={(e) => rateSession(e, session)}
              disabled={!rateSession}
            >
              {t("rate-session")}
            </DropdownItem>
          )}
          {canDisplayMenu("edit-rate-session") && (
            <DropdownItem
              href="#"
              onClick={(e) => rateSession(e, session)}
              disabled={!rateSession}
            >
              {t("edit-rate-session")}
            </DropdownItem>
          )}
          {canDisplayMenu("add-cilc-request-id") && (
            <DropdownItem
              href="#"
              onClick={(e) => openAddCILCReuqestIDModal(session)}
              disabled={!openAddCILCReuqestIDModal}
            >
              {session["CILC Request ID"]
                ? "Edit CILC Request ID"
                : "Add CILC Request ID"}
            </DropdownItem>
          )}
          {canDisplayMenu("internal-id") && (
            <DropdownItem
              href="#"
              onClick={(e) => openInternalIDModal(session)}
              disabled={!openInternalIDModal}
            >
              {session["Provider Internal ID"]
                ? t("edit-internal-id")
                : t("add-internal-id")}
            </DropdownItem>
          )}
          {canDisplayMenu("assign-presenter") && presentersForProvider.length > 0 && (
            <DropdownItem
              href="#"
              onClick={(e) => assignPresenter(session)}
              disabled={
                !assignPresenter ||
                !presentersForProvider ||
                !presentersForProvider.length
              }
            >
              {t("assign-presenter")}
            </DropdownItem>
          )}
          {canDisplayMenu("edit-request") && (
            <DropdownItem
              href="#"
              onClick={(e) => editRequest(session)}
              disabled={!editRequest}
            >
              {t("edit-request")}
            </DropdownItem>
          )}
          {canDisplayMenu("book-confirm") && (
            <DropdownItem
              href="#"
              onClick={(e) => bookConfirm(e, session)}
              disabled={!bookConfirm}
            >
              {t("book-confirm")}
            </DropdownItem>
          )}
          {canDisplayMenu("status-inplanning") && session.Status !== 'In Planning' && (!session['Provider Autobook?'] || String(session['School Autobook Status']) !== 'Immediate (No Lead input)') && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "In Planning")}
              disabled={!changeStatus}
            >
              {t("set-status-inplanning")}
            </DropdownItem>
          )}
          {canDisplayMenu("status-indevelopment") && session.Status !== 'Pending Provider Response' && (!session['Provider Autobook?'] || String(session['School Autobook Status']) !== 'Immediate (No Lead input)') && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "Pending Provider Response")}
              disabled={!changeStatus}
            >
              {t("set-status-indevelopment")}
            </DropdownItem>
          )}
          {canDisplayMenu("status-noresponse") && session['Days since Created'] > 5 && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "No response from Provider")}
              disabled={!changeStatus}
            >
              {t("set-status-noresponse")}
            </DropdownItem>
          )}
          {canDisplayMenu("status-requested") && (session.Status === 'Wish List' || session.Status === 'Pending Provider Response' || session.Status === 'In Planning') && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "Requested")}
              disabled={!changeStatus}
            >
              {t("set-status-requested")}
            </DropdownItem>
          )}
          {canDisplayMenu("status-unfulfilled") && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "Request Rejected by Provider")}
              disabled={!changeStatus}
            >
              {t("set-status-unfulfilled")}
            </DropdownItem>
          )}

          {canDisplayMenu("request-payment") && (
            <DropdownItem
              href="#"
              onClick={(e) => requestPayment(e, session)}
              disabled={!requestPayment}
            >
              {t("request-payment")}
            </DropdownItem>
          )}

          {canDisplayMenu("generate-invoice") && (
            <DropdownItem
              href="#"
              onClick={(e) => generateInvoice(e, session)}
              disabled={!generateInvoice}
            >
              {t("generate-invoice")}
            </DropdownItem>
          )}

          {canDisplayMenu("status-completed") && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "Completed")}
              disabled={!changeStatus}
            >
              {t("set-status-completed")}
            </DropdownItem>
          )}

          {canDisplayMenu("status-cancelled") && (
            <DropdownItem
              href="#"
              onClick={(e) => cancelSession(e, session)}
              disabled={!cancelSession}
            >
              {t("set-status-cancelled")}
            </DropdownItem>
          )}

          {canDisplayMenu("status-cancelledwithoutpayment") && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "Cancelled without Payment")}
              disabled={!changeStatus}
            >
              {t("set-status-cancelledwithoutpayment")}
            </DropdownItem>
          )}

          {canDisplayMenu("status-cancelledpayment") && (
            <DropdownItem
              href="#"
              onClick={(e) => changeStatus(e, session, "Cancelled with Payment")}
              disabled={!changeStatus}
            >
              {t("set-status-cancelledpayment")}
            </DropdownItem>
          )}

          {canDisplayMenu("session-result") && (
            <DropdownItem
              href="#"
              onClick={(e) => openSessionResult(e, session)}
              disabled={!openSessionResult}
            >
              {t("open-session-result")}
            </DropdownItem>
          )}

          {canDisplayMenu("provider-feedback") && (
            <DropdownItem
              href="#"
              onClick={(e) => openProviderFeedbackModal(e, session)}
              disabled={!openProviderFeedbackModal}
            >
              {session["Provider Rating"] ? "Edit Feedback" : t("leave-feedback")}
            </DropdownItem>
          )}

          {canDisplayMenu("add-to-collection") && (
            <DropdownItem
              href="#"
              onClick={(e) => addToCollection(e, session)}
              disabled={!addToCollection}
            >
              Add to Collection
            </DropdownItem>
          )}

          {canDisplayMenu("send-to-teacher") && (
            <DropdownItem
              href="#"
              onClick={(e) => sendToTeacher(e, session)}
              disabled={!sendToTeacher}
            >
              Send to Teachers
            </DropdownItem>
          )}

          {canDisplayMenu("duplicate-session") && userInfo["Status"] !== 'Session Host' && (
            <DropdownItem
              href="#"
              onClick={(e) => duplicateSession(e, session)}
              disabled={!duplicateSession}
            >
              Duplicate Session
            </DropdownItem>
          )}

          {canDisplayMenu("assign-to-funding-pool") && userInfo["Status"] === 'TakingITGlobal Staff' && (
            <DropdownItem
              href="#"
              onClick={(e) => assignToFundingPool(e, session)}
              disabled={!assignToFundingPool}
            >
              Assign to Funding Pool
            </DropdownItem>
          )}

          {canDisplayMenu("cancel-request") && (
            <DropdownItem
              href="#"
              onClick={(e) => cancelRequestedOrBookedSession(session)}
              disabled={!cancelRequestedOrBookedSession}
            >
              Cancel Request
            </DropdownItem>
          )}

          {canDisplayMenu("cancel-booking") && session["Type of Session"] !== 'Premium Session' && session["Type of Session"] !== 'Spotlight Series' && (
            <DropdownItem
              href="#"
              onClick={(e) => cancelRequestedOrBookedSession(session)}
              disabled={!cancelRequestedOrBookedSession}
            >
              Cancel Booking
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderSessionPhoto = () => {
    if (userType === "Provider" || userType === "Presenter") {
      if (session["School Photo"]) {
        return (
          <img
            alt=""
            src={session["School Photo"][0].url}
            className="session-card-media"
          />
        );
      }
    } else if (session["Optimized Image"]) {
      return (
        <img
          alt=""
          src={session["Optimized Image"][0].url}
          className="session-card-media"
        />
      );
    } else if (session["Image/Photo"]) {
      return (
        <img
          alt=""
          src={session["Image/Photo"][0].url}
          className="session-card-media"
        />
      );
    }

    return <img alt="" src={Placeholder} className="session-card-media" />;
  };

  const preRequisiteSatisfied = canDisplayInfo("request-session") &&
    (session["Status"] === "Active (Open for booking)" || session["Status"] === "Consider Another Option (See notes)") &&
    (
      (!session["Cluster-specific"]) || 
      (Array.isArray(session["Cluster-specific Cluster IDs"]) && session["Cluster-specific Cluster IDs"].some(clusterId => userInfo['Cluster'].includes(clusterId)))
    ) &&
    (!session["Prerequisite Session"] || (
      Array.isArray(userInfo["Provider Sessions Completed"]) && 
      Array.isArray(session["Prerequisite Session"]) && 
      session["Prerequisite Session"].every(prereq => 
        userInfo["Provider Sessions Completed"].includes(prereq)
      )
    ));
    
  return (
    <>
      <div className="session-item mb-3">
        <Row>
          <Col className="session-image-wrap" lg="5">
            <div className="session-date-wrap">
              {((session["Provider Autobook?"] && session.Status === 'Requested') || session.Status === 'Pending Provider Response' || session.Status === 'Pending Teacher Response') && (
                <div
                  className="session-date"
                  style={{ backgroundColor: session.Status === 'Pending Teacher Response' ? '#ffb6c1' : '#ffffa9' }}
                >
                  {session.Status === 'Pending Teacher Response' ? t('pending-teacher-resp') : t('pending-provider-resp')}
                </div>
              )}
              {session["Session Start Date/Time"] && (
                <div
                  className="session-date"
                  style={session['Status'] === 'Wish List' ? { backgroundColor: '#ffcccb' } : {}}
                >
                  {(canDisplayInfo("Time Options") ||
                    canDisplayInfo("Alternative-Date")) &&
                    `Option 1: `}{session['Status'] === 'Wish List' && (`Wishlisted for `)}
                  {getNiceFormatDateTimeString(
                    new Date(session["Session Start Date/Time"]),
                    appLocale,
                    dateType,
                  )}
                </div>
              )}

              {session["Alternative Date/Time"] && canDisplayInfo("Time Options") && (
                <div
                  className={classnames({
                    "session-date": true,
                    "wish-list": session['Status'] === 'Wish List'
                  })}
                >
                  Option 2:{" "}{session['Status'] === 'Wish List' && (`Wishlisted for `)}
                  {getNiceFormatDateTimeString(new Date(session["Alternative Date/Time"]), appLocale)}
                </div>
              )}

              {session["Alternative Date/Time"] && canDisplayInfo("Alternative-Date") && (
                <div
                  className={classnames({
                    "session-date": true,
                    "wish-list": session['Status'] === 'Wish List'
                  })}
                >
                  Option 2:{" "}{session['Status'] === 'Wish List' && (`Wishlisted for `)}
                  {getNiceFormatDateTimeString(new Date(session["Alternative Date/Time"]), appLocale)}
                </div>
              )}
            </div>

            {canDisplayInfo("Image/Photo") ? (
              renderSessionPhoto()
            ) : (
              <img
                alt=""
                src={Placeholder}
                className="session-card-media"
              />
            )}
          </Col>
          <Col>
            <div className="session-card-container">
              <div className="session-card-content">
                <div className="session-item-info">
                  <span
                    className={classnames({
                      "session-item-info-header": true,
                      hyper: sessionDetailLink ? true : false,
                    })}
                    onClick={() => window.location.href = `/cn/session/${sessionDetailLink}`}
                  >
                    {session["Custom Session Title"]
                      ? session["Custom Session Title"]
                      : session["Session Title Text"]
                        ? session["Session Title Text"]
                        : session["Session Title"]}
                  </span>
                  <div className="session-item-info-subheader-container">
                    {userType !== 'Teacher' && session["Students"] != null && session["Students"] > 0 && (
                      <>
                        <span className="session-item-info-subheader">Audience{" "}</span>
                        <span>{session["Students"]} {session["Students"] === 1 ? 'student' : 'students'}</span>&nbsp;&nbsp;
                      </>
                    )}
                    {canDisplayInfo("Grade(s)") && session["Grade(s)"] && session["Grade(s)"].length && (
                      <>
                        <span className="session-item-info-subheader">{t("grades")}{" "}</span>
                        {grades.map((grade, i) => (
                          <span key={i}>
                            {grade}
                            {i !== grades.length - 1 && ","}{" "}
                          </span>
                        ))}
                      </>
                    )}
                    {session["Length (Minutes)"] && (
                      <>
                        &nbsp;<span className="session-item-info-subheader">{t("length")}</span>
                        <span>{" "}{session["Length (Minutes)"]} mins</span>
                      </>
                    )}
                  </div>
                  <div className="session-item-info-subheader-container">
                    {
                      canDisplayInfo("Primary Subject") && session["Primary Subject Text"] && (
                        <>
                          <span className="session-item-info-subheader">
                            {t("subjects")}{" "}
                          </span>
                          {validSubjectsCurriculum.map((subjectId, i) => (
                            <span key={i}>
                              {appLocale === 'fr' ? subjectMap[subjectId]["Subject (FR)"] : subjectMap[subjectId]["Subject"]}
                              {i !== validSubjectsCurriculum.length - 1 && ","}{" "}
                            </span>
                          ))}
                          {validSubjects.map((subjectId, i) => (
                            <span key={i}>
                              {appLocale === 'fr' ? subjectMap[subjectId]["Subject (FR)"] : subjectMap[subjectId]["Subject"]}
                              {i !== validSubjects.length - 1 && ","}{" "}
                            </span>
                          ))}
                        </>
                      )
                    }
                  </div>
                  {
                    (canDisplayInfo("Teacher School Name") || Array.isArray(userInfo["Cluster Leadership"]) || (Array.isArray(userInfo["School Lead Name"]) && userInfo["School Lead Name"].length > 1)) && session["Type of Session"] !== 'Premium Session' && session["Type of Session"] !== 'Spotlight Series' && session["# Schools"] < 5 ? (
                      <div>
                        <span className="title-tag">
                          <img src={TeacherIcon} className="img-pr" alt="" />{" "}
                          {(session["Status"] === "Requested" || session["Status"] === "In Planning" || session["Status"] === "Pending Provider Response") ? t("requested-by") : t("booked-for")}:
                          {session["Teacher Name"] ? session["Teacher Name"].map((teacher, i) => (
                            <span key={`teacher${i}`}>
                              {i !== 0 ? ", " : " "}
                              <span
                                className={!hideActionContacts && userType !== "Provider" ? "teacher-tag" : "school-tag"}
                                onClick={
                                  !hideActionContacts && userType !== "Provider"
                                    ? (e) => gotoURL(e, `mailto:${session["Teacher Email"]}`)
                                    : undefined
                                }
                                style={{ cursor: !hideActionContacts && userType !== "Provider" ? "pointer" : "default" }}
                              >
                                {teacher}
                              </span>
                            </span>
                          )) : null}
                          {session["School Name Text"]?.map((school, i) => (
                            <span key={i}>
                              {", "}
                              {session["School Profile Link"] && session["School Profile Link"][i] && session["School Profile Link"][i] !== "https://www.connectednorth.org/en/schools/tig" ? (
                                <a href={session["School Profile Link"][i]} target="_blank" rel="noopener noreferrer">
                                  <span className="school-tag">{school}</span>
                                </a>
                              ) : (
                                <span className="school-tag">{school}</span>
                              )}
                            </span>
                          ))}
                        </span>
                      </div>
                    ) : null
                  }
                  {
                    canDisplayInfo("Presenters") &&
                      session["Presenters"] ? (
                      <div>
                        <span className="title-tag">
                          {" "}
                          <img
                            src={ProviderIcon}
                            className="img-pr"
                            alt=""
                          />{" "}
                          Presented by:
                          {session["Presenter Name"].map(
                            (presenter, i) => (
                              <span key={i}>
                                {i !== 0 ? ", " : " "}
                                <span className="host-tag">
                                  {presenter}
                                  {session["Provider Pronouns Text"] && session["Provider Pronouns Text"][i] && (
                                    <span> ({session["Provider Pronouns Text"][i]})</span>
                                  )}
                                </span>
                              </span>
                            ),
                          )}
                        </span>
                      </div>
                    ) : (canDisplayInfo("Provider Name") && session["Provider Name"] && (
                      <div>
                        <span className="title-tag">
                          {" "}
                          <img
                            src={ProviderIcon}
                            className="img-pr"
                            alt=""
                          />{" "}
                          {t("provider")}:
                          {session["Provider Name"].map(
                            (provider, i) => (
                              <span key={i}>
                                {i !== 0 ? ", " : " "}
                                <span
                                  className="host-tag-linked"
                                  href="#"
                                  onClick={() => window.location.href = `/cn/provider/${session["Provider"][i]}`}
                                >
                                  {provider}
                                </span>
                                {session["Provider Pronouns Text"] && session["Provider Pronouns Text"][i] && (
                                    <span> ({session["Provider Pronouns Text"][i]})</span>
                                )}
                                {
                                  session["Provider Timezone"] &&
                                  session["Status"] !== "Booked" &&
                                  session["Status"] !== "Completed" &&
                                  (<span>&nbsp;({removePrefix(session["Provider Timezone"][i])} Time)</span>)
                                }
                              </span>
                            ),
                          )}
                        </span>
                      </div>
                    ))
                  }
                  <div className="d-md-flex flex-column" style={{ justifyContent: 'space-between' }}>
                    {canDisplayInfo("Provider Name") &&
                      session["Provider Name Text"] &&
                      !hideProvider && (
                        <div className="mb-2">
                          <span className="title-tag">
                            {" "}
                            <img
                              src={ProviderIcon}
                              className="img-pr"
                              alt=""
                            />{" "}
                            {t("provider")}:
                            {session["Provider Name Text"].map(
                              (provider, i) => (
                                <span key={i}>
                                  {i !== 0 ? ", " : " "}
                                  <span
                                    className="host-tag-linked"
                                    href="#"
                                    onClick={() => window.location.href = `/cn/provider/${session["Provider"][i]}`}
                                  >
                                    {provider}
                                  </span>
                                  {session["Indigenous?"] && (
                                    <> ({(session["Indigenous Cultural Group(s)"] || session["Indigenous Group(s)"] || []).map(group => {
                                      const encodedGroup = encodeURIComponent(group);
                                      const url = session["Indigenous Cultural Group(s)"] 
                                        ? `/cn/search?indigenousGroups=First+Nations&culturalGroup=${encodedGroup}`
                                        : `/cn/search?indigenousGroups=${encodedGroup}`;
                                      return <a href={url} key={group}>{group}</a>;
                                    }).reduce((prev, curr) => [...prev, " and ", curr], []).slice(1)})</>
                                  )}
                                </span>
                              ),
                            )}
                            &nbsp;{session["Diversity Adjustment"] < 0 && (
                              <>
                                <i className="fa fa-star" id="DiscountTooltip" style={{ color: "#00b80c" }} />
                                <UncontrolledTooltip target="DiscountTooltip">New provider: 50% off for the first 10 sessions!</UncontrolledTooltip>
                              </>
                            )}
                          </span>
                        </div>
                      )}

                    {(canDisplayInfo("favorited-teachers") &&
                      !!session["Favorited Teachers"]) && (
                        <div>
                          <span className="title-tag">
                            <i className="fa fa-heart-o" />&nbsp;&nbsp;
                            Favourited by:{" "}
                          </span>
                          <span>{
                            session["Favorited Teachers"]
                              .map(teacher => teacher["Teacher Name"]).join(", ")
                          }</span>
                        </div>
                      )}

                    {canDisplayInfo("Session Host") &&
                      session["Session Host(s)"] ? (
                      <div>
                        <span className="title-tag">
                          {" "}
                          <img
                            src={ProviderIcon}
                            className="img-pr"
                            alt=""
                          />{" "}
                          {t("hosted-by")}:{" "}
                          {session["Session Host Text"].map(
                            (host, i) => (
                              <span key={i}>
                                {i !== 0 ? ", " : " "}
                                <span className="host-tag">
                                  {host}
                                </span>
                              </span>
                            ),
                          )}
                        </span>
                      </div>
                    ) : (
                      canDisplayInfo("School Lead") &&
                      session["School Lead Text"] && (
                        <div>
                          <span className="title-tag">
                            {" "}
                            <img
                              src={ProviderIcon}
                              className="img-pr"
                              alt=""
                            />{" "}
                            {t("hosted-by")}:{" "}
                            {session["School Lead Text"].map(
                              (schoolLead, i) => (
                                <span key={i}>
                                  {i !== 0 ? ", " : " "}
                                  <span className="host-tag">
                                    {schoolLead}
                                  </span>
                                </span>
                              ),
                            )}
                          </span>
                        </div>
                      )
                    )}
                    {
                      canDisplayInfo("Credits") && (
                        session["Credit Cost"] ? (
                          <div className="d-flex align-items-center credits-tag">
                            <TokenBlue className="token-blue" />
                            {session["Credits"] === 0 || session["Type of Session"] === "Premium Session" || session["Type of Session"] === "Spotlight Series" || (session["Type of Session"] === "Teacher PD" && session["Custom Session Title"] && (session["Custom Session Title"].includes("Group Professional Learning") || session["Custom Session Title"].includes("PD Week")))
                              ? t("free")
                              : suf(session["Credit Cost"])
                            }
                          </div>
                        ) : (
                          <div className="d-flex align-items-center credits-tag">
                            <TokenBlue className="token-blue" />
                            {getCreditStatus()}
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
                <div className="session-info-top-buttons">
                  {userType === "Teacher" && (
                    <button
                      type="button"
                      className="btn btn-secondary btn-favourite"
                      onClick={() => toggleFavorite()}
                      disabled={favoriteState === "loading"}
                    >
                      {favoriteState === "loading" ? (
                        <Loading size={14} />
                      ) : (
                        <i
                          className={
                            favoriteState === "unfavorite"
                              ? "fa fa-heart-o fa-lg "
                              : "fa fa-heart fa-lg favorite"
                          }
                        />
                      )}
                    </button>
                  )}
                  {renderActionMenu()}
                </div>
              </div>
              <div className="session-card-button-group">
                {canDisplayMenu("host-session") && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => hostSessionCallback(session)}
                  >
                    Host Session
                  </button>
                )}

                {canDisplayMenu("confirm-actions") && ((userType === 'Provider' && session.Status !== 'Pending Teacher Response') || (userType === 'Teacher' && session.Status === 'Pending Teacher Response')) && (
                  <button
                    className="btn btn-primary"
                    disabled={!confirmBooking}
                    onClick={(e) =>
                      confirmBooking(
                        e,
                        session["Session Start Date/Time"],
                        session["Alternative Date/Time"],
                        `Option 1: ${getNiceFormatDateTimeString(
                          new Date(session["Session Start Date/Time"]),
                          appLocale,
                        )}`,
                      )
                    }
                  >
                    {session["Alternative Date/Time"] ? t("confirm-option-1") : "Confirm Requested Time"}
                  </button>
                )}

                {canDisplayMenu("confirm-actions") && session["Alternative Date/Time"] && ((userType === 'Provider' && session.Status !== 'Pending Teacher Response') || (userType === 'Teacher' && session.Status === 'Pending Teacher Response')) && (
                  <button
                    className="btn btn-primary"
                    disabled={!confirmBooking}
                    onClick={(e) =>
                      confirmBooking(
                        e,
                        session["Alternative Date/Time"],
                        session["Session Start Date/Time"],
                        `Option 2: ${getNiceFormatDateTimeString(
                          new Date(session["Alternative Date/Time"]),
                          appLocale,
                        )}`,
                      )
                    }
                  >
                    {t("confirm-option-2")}
                  </button>
                )}

                {canDisplayMenu("confirm-actions") && ((userType === 'Provider' && session.Status !== 'Pending Teacher Response') || (userType === 'Teacher' && session.Status === 'Pending Teacher Response')) && (
                  <button
                    className="Btn-Red"
                    onClick={(e) => setRejectModal(true)}
                  >
                    {t("reject")}
                  </button>
                )}

                {canDisplayInfo("join-session") && session["WebEx/Zoom Link"] && (
                  <button
                    className="btn btn-primary"
                    onClick={() => openURL(session["WebEx/Zoom Link"])}
                    disabled={!session["WebEx/Zoom Link"].startsWith("http")}
                  >
                    {userType === 'Team' ? "Join" : t("join-session")}
                  </button>
                )}
                {canDisplayInfo("rate-session") && (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => rateSession(e, session)}
                    disabled={!rateSession}
                  >
                    {t("rate-session")}
                  </button>
                )}

                {canDisplayInfo("request-session") && session["Available in Events?"] && (
                  <button
                    className="btn btn-primary"
                    onClick={() => (window.location.href = `/cn/events`)}
                  >
                    {t("register-free-events")}
                  </button>
                )}
                {preRequisiteSatisfied && userInfo["Status"] !== 'Session Host' && (
                  <button
                    className="btn btn-primary"
                    onClick={async () => {
                      let supply = null;
                      if (session.Supplies && session.Supplies.length) {
                        supply = await airtable.supplies.select(session["Supplies"][0]);
                      }
                      dispatch(setRequestSessionModal({
                        visible: true,
                        providerSession: session,
                        dayRestrictionConfig: getDayRestrictionConfig(supply),
                        onSubmitted: () => dispatch(setRequestSessionModal({}))
                      }))
                    }}
                  >
                    {t("request-session")}
                  </button>
                )}
                {canDisplayInfo("book-session") && (
                  <button
                    className="btn btn-primary"
                    onClick={() => bookSession()}
                  >
                    {t("book-session")}
                    {String(session["Autobook Provider?"]) === 'true' && <> <i className="fa fa-magic" aria-hidden="true"></i></>}
                  </button>
                )}
                {canDisplayInfo("request-session") && session["Prerequisite Session"] && !preRequisiteSatisfied &&
                  (session["Status"] === "Active (Open for booking)" || session["Status"] === "Consider Another Option (See notes)") && (
                    <button
                      className="btn btn-primary"
                      onClick={() => (window.location.href = `/cn/session/${session["Prerequisite Session"][0]}`)}
                    >
                      {t("view-prerequisite")}
                    </button>
                  )}

                {canDisplayMenu("open-detail") && !!sessionDetailLink && (
                  <Fragment>
                    <button
                      id="btn-session-details"
                      className="btn btn-outline-primary alt"
                      onClick={() => window.location.href = `/cn/session/${sessionDetailLink}`}
                      onContextMenu={handleSessionDetailBtnClick}
                    >
                      {userType === 'Team' ? "Details" : t("session-details")}
                    </button>
                    <Menu
                      id="menu-session-details"
                      MenuListProps={{
                        'aria-labelledby': 'btn-session-details',
                      }}
                      anchorEl={anchorEl}
                      open={openInNewTabMenuOpen}
                      onClose={handleSessionDetailBtnClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem onClick={openInNewTab}>Open in a new tab</MenuItem>
                    </Menu>
                  </Fragment>
                )}
                {canDisplayMenu("add-my-class") && session["Type of Session"] !== 'Premium Session' && session["Type of Session"] !== 'Spotlight Series' && !session["Custom Session Title"]?.includes("PD Week") && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openAddMyClassModal(session)}
                  >
                    {t("add-my-class")}
                  </button>
                )}
                {canDisplayMenu("add-my-class") && (session["Type of Session"] === 'Premium Session' || session["Type of Session"] === 'Spotlight Series' || session["Custom Session Title"]?.includes("PD Week")) && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => (window.location.href = `/cn/events`)}
                  >
                    {t("register-free-events")}
                  </button>
                )}
                {canDisplayMenu("track-supplies") && session["Resource Tracking URL"] && session["Resource Tracking URL"].length && session["Resource Tracking URL"][0] && session["Resource Tracking URL"][0].startsWith("http") && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openURL(session["Resource Tracking URL"])}
                  >
                    {t("track-supplies")}
                  </button>
                )}
                {((canDisplayMenu("open-rubric") && session["Minutes until Session"] < 600) || rubriclink) && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openURL(rubriclink || session["Rubric Form Link"])}
                  >
                    Rubric
                  </button>
                )}
                {userType === "Team" && userInfo["Status"] !== "Session Host" && session["AI Activities Link"] && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openURL(session["AI Activities Link"])}
                  >
                    {t("activities")}
                  </button>
                )}

                {(!session["Type of Session"] ||
                  (!session["Type of Session"].includes("Premium") &&
                    !session["Type of Session"].includes("Spotlight"))) &&
                  canDisplayMenu("cancel-session") &&
                  session['Status'] !== 'Completed' &&
                  !session['Status'].startsWith("Cancelled") && (
                    <button
                      className="btn btn-outline-primary alt"
                      onClick={(e) => cancelSession(e, session)}
                    >
                      {t("cancel")}
                    </button>
                  )}

                {canDisplayMenu("cilc-request") && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openURL(session["CILC Request URL"])}
                  >
                    CILC {session["CILC Request ID"]}
                  </button>
                )}

                {(userType === 'Provider' || userType === 'Presenter') && session["# Schools"] === 1 && session["School Profile Link"] && session["School Profile Link"][0] !== "https://www.connectednorth.org/en/schools/tig" && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openURL(session["School Profile Link"])}
                  >
                    School Profile
                  </button>
                )}

                {canDisplayMenu("book-cilc") && session["Status"] !== 'Completed' && (
                  <button
                    className="btn btn-outline-primary alt"
                    // onClick={() => openURL(session["CILC Booking URL"])}
                    onClick={() => bookOnCILC(session)}
                  >
                    {t("book-on-cilc")}
                  </button>
                )}

                {canDisplayMenu("open-cilc-profile") && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => openURL(session["CILC Profile URL"])}
                  >
                    {t("open-cilc-profile")}
                  </button>
                )}

                {canDisplayMenu("student-survey") && session["Type of Session"] !== 'Teacher PD' && (
                  <button
                    className="btn btn-outline-primary alt"
                    onClick={() => studentSurvey(session)}
                  >
                    {t("student-survey")}
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {userType === "Provider" && rejectModal && (
        <ProviderSessionReject
          sessionId={session.id}
          onToggle={() => setRejectModal(false)}
          onPost={() => postSessionReject()}
        />
      )}

      {userType === "Teacher" && rejectModal && (
        <TeacherSessionReject
          sessionId={session.id}
          onToggle={() => setRejectModal(false)}
          onPost={() => postSessionReject()}
        />
      )}
    </>
  );
};

export default SessionInfo;
